import React, { createContext, useState, useContext } from 'react'

// Create a Loading Context
const StepperContext = createContext()

// Loading Provider to wrap the app
export const StepperProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0)

  return (
    <StepperContext.Provider value={{ activeStep, setActiveStep }}>
      {children}
    </StepperContext.Provider>
  )
}

// Custom hook to use the Loading Context
export const useStepper = () => useContext(StepperContext)
