import React, { createContext, useState, useContext } from 'react'

// Create a Loading Context
const NotificationContext = createContext()

// Loading Provider to wrap the app
export const NotificationProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0)

  return (
    <NotificationContext.Provider
      value={{ notificationCount, setNotificationCount }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

// Custom hook to use the Loading Context
export const useNotificationCount = () => useContext(NotificationContext)
